import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/Layout'
import { Posts } from '../components/Posts'
import { SEO } from '../components/SEO'
import { Hero } from '../components/Hero'
import { SidebarLayout } from '../components/SidebarLayout'
import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'

import gs_logo from '../assets/scholar_logo.png'
import { scholar } from '../data/links'

export default function Publications({ data }) {
  const publications = data.publications.edges
  const simplifiedPosts = useMemo(() => getSimplifiedPosts(publications), [publications])
  const title = 'Publications'
  const description = 'Papers I have published as first or last author.'

  return (
    <div>
      <Helmet title={`${title} | ${config.siteTitle}`} />
      <SEO customDescription={description} />

      <SidebarLayout>
      <Hero title={title} />
        <header className="hero-description">
            <p>Below, you can find a collection of my <strong>first and last author</strong> publications.<br/>
                  For a complete list of my publications please look at my <a
                  href={scholar}
                  title="Google Scholar profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={scholar}
                >
                  <span>Google Scholar profile</span>
                  <img src={gs_logo} alt="Google Scholar profile" />
                  </a>.
            </p>
        </header>
        <Posts data={simplifiedPosts} showYears />
      </SidebarLayout>
    </div>
  )
}

Publications.Layout = Layout

export const publicationsQuery = graphql`
  query publicationsQuery {
    publications: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "publication" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            categories
          }
        }
      }
    }
  }
`
